import React, { useState } from "react"
import PageSectionHeader from "../../common/PageSectionHeader"
import ImageRenderer from "../../atoms/ImageRenderer"
import PricingCard from "../../molecules/PricingCard"

const PricingSection = ({
  subTitle,
  title,
  description,
  percentageOffText,
  pricingArrowIcon,
  plans,
}) => {
  const [activeButton, setActiveButton] = useState("monthly")

  return (
    <div className="bg-[#0A1E46]">
      <div className="synkli-section--horizontal-space pt-24 md:pt-32 pb-24 mb-5 md:mb-10">
        <PageSectionHeader
          subTitle={subTitle}
          subTitleEC={`text-white border-white`}
          title={title}
          titleEC={`text-white !max-w-[680px]`}
        />
        <p
          className="mt-5 font-[200] text-[18px] font-poppins text-white text-center"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="relative max-w-[max-content] mx-auto mt-12">
          <div className="bg-white rounded-full flex">
            <button
              className={`flex-1 px-7 md:px-9 py-5 font-[500] text-[#0A1E46] transiton duration-300 ${
                activeButton === "monthly"
                  ? "bg-[#B695F8] rounded-full"
                  : "bg-transparent"
              }`}
              onClick={() => setActiveButton("monthly")}
            >
              Monthly
            </button>
            <button
              className={`flex-1 px-8 md:px-10 py-5 font-[500] text-[#0A1E46] transiton duration-300  ${
                activeButton === "yearly"
                  ? "bg-[#B695F8] rounded-full"
                  : "bg-transparent"
              }`}
              onClick={() => setActiveButton("yearly")}
            >
              Yearly
            </button>
          </div>
          <div className="md:absolute top-[-10px] right-[-220px]">
            <div className="">
              <h3
                className="font-[600] text-white md:ml-10 mt-4 md:mt-0 text-center md:text-left"
                dangerouslySetInnerHTML={{ __html: percentageOffText }}
              />
              <div className="max-w-[70px] hidden md:block">
                <ImageRenderer
                  img={pricingArrowIcon.path}
                  alt={pricingArrowIcon.alt}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center flex-wrap gap-8 items-strech mt-12 md:mt-16">
          {plans &&
            plans.map((plan, index) => (
              <PricingCard
                key={`plan_` + index}
                pricingCardEC={`w-[100%] md:w-[48%] lg:w-[31%] mt-5`}
                tagLine={plan.tagLine}
                cardIcon={plan.cardIcon}
                title={plan.title}
                description={plan.description}
                amount={activeButton === "monthly" ? plan.monthlyPrice : plan.yearlyPrice}
                duration={activeButton === "monthly" ? `/mo` : "/yr"}
                button={plan.btn}
                features={plan.featres}
                featuresPlus={plan.featresPlus}
                addons={activeButton === "monthly" ? plan.monthly_addons : plan.annual_addons}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default PricingSection
