import React from "react"
import ImageRenderer from "../../atoms/ImageRenderer"
import Button from "../../atoms/Button"

const HeroSection = ({
  heroTitleFirstRowText,
  heroTitleSecondRowText,
  heroTitleThirdRowText,
  brushImage,
  description,
  btns,
}) => {
  return (
    <>
      <div className="synkli-section--horizontal-space pt-12 pb-6 sm:py-10">
        <h1
          className={`text-[#B695F8] text-[7.3vw] sm:text-[5.7vw] md:text-[6.5vw] lg:text-[7vw] leading-[9vw] font-[700] larger-screen-hero-title-first`}
        >
          {heroTitleFirstRowText}
          <span
            className={`block text-[#0A1E46] text-[8vw] sm:text-[6.2vw] md:text-[6.8vw] lg:text-[7.8vw] leading-[9.5vw] text-right font-[700] larger-screen-hero-title-second`}
          >
            {heroTitleSecondRowText}
          </span>

          <span
            className={`text-[#0A1E46] text-[8vw] sm:text-[6.2vw] md:text-[6.8vw] lg:text-[7.8vw] leading-[9.5vw] font-[700] larger-screen-hero-title-second`}
          >
            {heroTitleThirdRowText}
          </span>
        </h1>

        <div className="mt-1 md:mt-0 max-w-[50%]">
          <ImageRenderer img={brushImage.path} alt={brushImage.alt} />
        </div>

        <div className="text-center lg:text-left lg:max-w-[35%] lg:ml-auto mt-8 lg:mt-[-120px]">
          <p
            className="text-[#0A1E46] text-[18px] leading-[1.4em]"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="flex flex-col md:flex-row justify-center lg:justify-start gap-3 mt-8">
            {btns &&
              btns.map((btn, index) => (
                <Button
                  key={"heroBtn_" + index}
                  to={btn.to}
                  href={btn.href}
                  text={btn.text}
                  ec={btn.ec}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection
