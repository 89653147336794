import React from "react"
import LinkComponent from "../atoms/Link"
import ImageUrlRenderer from "../atoms/ImageUrlRenderer"
import useContentMaxlenghtWithDots from "../../hooks/useContentMaxlenghtWithDots"
import { stripHtmlTags } from "../../utils/utils"
import { blogsPostTypeSlug } from "../../Content/Global"
import { Link } from "gatsby"

const BlogCard = ({ componentEC, blog }) => {
  const ImageURL = ImageUrlRenderer({ img: blog.cardImage })

  return (
    <div className={"group " + componentEC}>
      <Link to={blogsPostTypeSlug + blog.slug}>
        <div
          className="min-h-[250px] md:min-h-[200px] lg:min-h-[350px] w-[100%] bg-cover bg-center rounded-[16px]"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url('${ImageURL}')`,
          }}
        ></div>
      </Link>
      <div
        className="max-w-[90%] mx-auto mt-[-60px] py-3 px-5 bg-white rounded-[18px] shadow-lg shadow-[#001F1F]-500 border border-[#001F1F08]  group-hover:mt-[-110px] group-hover:bg-[#0A1E46] "
        style={{ transition: `all 0.3s ease-in-out` }}
      >
        {blog.date ? (
          <h6 className="text-[#0A1E46] text-[11px] leading-[1.2em] font-[400] group-hover:text-[white]">
            {blog.date}
          </h6>
        ) : (
          ""
        )}

        <Link to={blogsPostTypeSlug + blog.slug}>
          <h2 className="text-[#222222] text-[18px] md:text-[14px] lg:text-[20px] leading-[1.2em] md:min-h-[48px] font-[600] mt-2 group-hover:text-[white]">
            {stripHtmlTags(useContentMaxlenghtWithDots(blog.title, 80))}
          </h2>
        </Link>
        <p className="text-[#222222] text-[14px] md:text-[12px] lg:text-[16px] leading-[1.4em] mt-2 group-hover:text-[white]">
          {useContentMaxlenghtWithDots(blog.description, 60)}
        </p>
        <LinkComponent
          to={blogsPostTypeSlug + blog.slug || "/"}
          href={blogsPostTypeSlug + blog.slug || ""}
          text={"See More"}
          ec={"!text-[14px] !text-[#B695F8] underline !leading-[20px] !mt-2"}
        />
      </div>
    </div>
  )
}

export default BlogCard
