import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"

const UsecaseCard = ({
  usecaseCardEC,
  title,
  titleEC,
  description,
  descriptionEC,
  image,
}) => {
  return (
    <div
      className={
        "flex flex-col px-5 lg:px-10 pt-10 lg:pt-16 pb-5 gap-5 sm:gap-7 shadow-md shadow-[#001F1F10] bg-[#F3F0FF] rounded-[24px] " +
          usecaseCardEC || ""
      }
    >
      <div className="px-2 flex flex-col gap-3 sm:gap-4 pb-5">
        <h2
          className={
            "text-[22px] md:text-[22px] lg:text-[28px] font-[600] text-[#2A2A2A] leading-[1.2em] " +
              titleEC || ""
          }
        >
          {title}{" "}
        </h2>
        <p
          className={
            "text-[14px] md:text-[18px] lg:text-[18px] text-[#2A2A2A] leading-[1.4em] " +
              descriptionEC || ""
          }
        >
          {description}
        </p>
      </div>
      <div className="max-w-[180px] md:max-w-[220px] lg:max-w-[200px] mx-auto">
        <ImageRenderer img={image.path} alt={image.alt} />
      </div>
    </div>
  )
}

export default UsecaseCard
