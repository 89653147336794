import React from "react"
import PageSectionHeader from "./PageSectionHeader"
import BlogCard from "../molecules/BlogCard"
import Button from "../atoms/Button"
import { blogPosts } from "../../Content/Blogs"
import { blogsPostTypeSlug } from "../../Content/Global"

const BlogSection = ({subTitle, title}) => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, #F3F0FF 60%, white 40%)`,
      }}
    >
      <div className="synkli-section--horizontal-space synkli-section--vertical-space">
        <PageSectionHeader
          subTitle={subTitle}
          title={title}
        />
        <div className="flex flex-wrap justify-start mt-10">
          {blogPosts.slice(0, 3).map((blog, index) => (
            <BlogCard
              key={"blog_" + index}
              componentEC={"w-full md:w-1/2 lg:w-1/3 px-2 mt-5"}
              blog={blog}
            />
          ))}
        </div>
        <div className="text-center mt-16">
          <Button
            to={blogsPostTypeSlug}
            href={""}
            text={"View All Blogs"}
            ec={
              "primary-btn px-6 lg:px-[50px] !py-1 sm:!py-2 lg:!py-3 inline-block"
            }
          />
        </div>
      </div>
    </div>
  )
}

export default BlogSection
