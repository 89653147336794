import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"

const HeroSectionAnimationItem = ({ peopleEC, personEC, imageTop, imageBottom }) => {
  return (
    <div className={"synkli-hero-circle-people " + peopleEC}>
      <div className={"synkli-hero-circle-person top " + personEC}>
        <div className={"synkli-hero-circle-person-spinner " + peopleEC}>
          <div role="listitem" className="synkli-hero-wheel-card w-dyn-item">
            <div className="max-w-[220px]">
              <ImageRenderer img={imageTop.path} alt={imageTop.alt} />
            </div>
          </div>
        </div>
      </div>
      <div className={"synkli-hero-circle-person bottom " + personEC}>
        <div className={"synkli-hero-circle-person-spinner " + peopleEC}>
          <div role="listitem" className="synkli-hero-wheel-card w-dyn-item">
            <div className="max-w-[220px]">
                <ImageRenderer img={imageBottom.path} alt={imageBottom.alt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSectionAnimationItem
