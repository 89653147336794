import React from "react"
import PageSectionHeader from "./PageSectionHeader"
import UsecaseCard from "../molecules/UsecaseCard"

const UseCases = ({ subTitle, title, useCases }) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <PageSectionHeader
        subTitle={subTitle}
        title={title}
        titleEC={`!max-w-[1200px]`}
      />
      <div className="flex flex-col md:flex-row gap-6 sm:gap-10 justify-center mt-14">
        {useCases &&
          useCases.map((usecase, index) => (
            <UsecaseCard
              key={`usecase_ `+ index}
              usecaseCardEC={`flex-[1]`}
              title={usecase.title}
              description={usecase.description}
              image={usecase.image}
            />
          ))}
      </div>
    </div>
  )
}

export default UseCases
