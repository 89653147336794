import React from "react"
import HeroSectionAnimationItem from "../../molecules/HeroSectionAnimationItem"
import { HeroSectionAnimationItems } from "../../../Content/Home"

const HeroSectionAfterAnimation = () => {
  return (
    <div className="pt-[100px] md:pt-[450px] pb-[290px] relative overflow-hidden">
      <div className="pointer-events-none min-w-[1000px] md:min-w-[1600px] absolute top-0 bottom-0 left-1/2 right-0 transform -translate-x-1/2">
        <div className="pointer-events-none w-full pt-[100%] absolute top-0 bottom-0 left-1/2 right-0 transform -translate-x-1/2">
          {HeroSectionAnimationItems.map((mainObject, index) => (
            <div
              className={`synkli-hero-circle-rotator ${mainObject.name}`}
              key={"heroSectionDiv_" + index}
            >
              {mainObject.items.map((item, i) => (
                <div key={"heroSectionDivChild_" + index + "_" + i}>
                  <HeroSectionAnimationItem
                    peopleEC={item.peopleEC}
                    personEC={item.personEC}
                    imageTop={{
                      path: item.imageTop.path,
                      alt: item.imageTop.alt,
                    }}
                    imageBottom={{
                      path: item.imageBottom.path,
                      alt: item.imageBottom.alt,
                    }}
                  />
                </div>
              ))}
            </div>
          ))}

          <div className="synkli-hero--rings-wrapper">
            <div className="synkli-hero--animated-ring cc-2"></div>
            <div className="synkli-hero--animated-ring cc-3"></div>
            <div className="synkli-hero--animated-ring cc-4"></div>
            <div className="synkli-hero--animated-ring cc-5"></div>
            <div className="synkli-hero--animated-ring cc-6"></div>
            <div className="synkli-hero--animated-ring cc-7"></div>
            <div className="synkli-hero--animated-ring cc-8"></div>
            <div className="synkli-hero--animated-ring cc-9"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSectionAfterAnimation
